<template>
    <h6>{{ title }}</h6>
</template>

<script>
export default {
    mounted () {
        console.log(`Tab "${this.title}" was mounted. Because of keep-alive, it should only load once, even if you move between tabs`)
    },
    props: {
        title: {
            type: String,
            default: ""
        }
    }
}
</script>
