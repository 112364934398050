<template>
    <page
        id="ui-catalog"
        :title="$root.translate('UI Catalog')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <div>
            <h3 class="my-4">Buttons</h3>

            <default-button
                :waiting="waiting"
                color="primary"
                @click.prevent="wait"
                class="mr-3"
            >
                Wait
            </default-button>
            <default-button
                flavor="link"
                color="warning"
                class="mr-3"
            >
                Warning
            </default-button>

            <feedback-button
                :status="feedback_status"
                color="primary"
                @click="feedback_status=true"
                @dismiss="feedback_status=null"
                class="mr-3 mt-3"
            >
                Feedback
            </feedback-button>

            <h3 class="my-4">Inputs</h3>
            <base-form
                :has-required-fields="true"
                @submit.prevent.stop
            >
                <div class="columns">
                    <div class="column col-3">
                        <form-input
                            id="email"
                            name="email"
                            type="email"
                            v-model="email"
                            :required="true"
                        >
                            <template v-slot:label>{{ $root.translate("Email") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-2">
                        <password-input
                            id="password"
                            name="password"
                            v-model="password"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Password") }}</template>
                        </password-input>
                    </div>
                    <div class="column col-7">
                        <password-editor
                            id="password-editor"
                            name="password"
                            v-model="password"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Password Editor") }}</template>
                        </password-editor>
                    </div>

                    <div class="column col-1 mt-2">
                        <form-input
                            id="amount"
                            type="float"
                            name="amount"
                            v-model="amount"
                            :required="false"
                        >
                            <template v-slot:left_addon>
                                <span class="input-group-addon">
                                    <open-icon glyph="dollar-sign" />
                                </span>
                            </template>
                            <template v-slot:label>{{ $root.translate("Amount") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-2 mt-2">
                        <form-select
                            id="user_id"
                            name="user_id"
                            v-model="user_id"
                            data-source="user"
                            :empty-value="0"
                            :empty-label="$root.translate('Pick a user')"
                            :show-all="false"
                            :required="true"
                        >
                            <template v-slot:label>{{ $root.translate("User") }}</template>
                        </form-select>
                    </div>
                    <div class="column col-2 mt-2">
                        <form-dropdown
                            id="role"
                            name="role"
                            v-model="role"
                            :options="roles"
                            :empty-value="0"
                            :empty-label="$root.translate('Pick a role')"
                            :required="true"
                        >
                            <template v-slot:label>{{ $root.translate("Role") }}</template>
                        </form-dropdown>
                    </div>
                    <div class="column col-7 mt-2">
                        <form-date-input
                            id="meeting_on"
                            name="meeting_on"
                            v-model="meeting_on"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Meeting On") }}</template>
                        </form-date-input>
                    </div>

                    <div class="column col-3 mt-2">
                        <form-tag-input
                            id="current_tags"
                            name="current_tags"
                            v-model="current_tags"
                            :data-source="tags"
                            :ad-hoc="true"
                            :required="false"
                            @create="add_tag"
                        >
                            <template v-slot:label>{{ $root.translate("Tags (ad hoc)") }}</template>
                        </form-tag-input>
                    </div>
                    <div class="column col-3 mt-2">
                        <form-tag-input
                            id="team"
                            name="team"
                            v-model="team"
                            data-source="user"
                            :show-all="false"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Team") }}</template>
                        </form-tag-input>
                    </div>
                    <div class="column col-6 mt-2">
                        <form-token-input
                            id="emails"
                            name="emails"
                            :value="emails"
                            :tokenValidator="validate_email"
                            :required="true"
                            @input="add_email"
                            @delete="remove_email"
                        >
                            <template v-slot:label>{{ $root.translate("Bcc") }}:</template>
                        </form-token-input>
                    </div>

                    <div class="column col-3 mt-2">
                        <form-toggle-input
                            id="meeting_confirmed"
                            name="meeting_confirmed"
                            type="checkbox"
                            v-model="meeting_confirmed"
                            :horizontal="true"
                            :required="false"
                        >
                            {{ $root.translate('Confirmed') }}
                        </form-toggle-input>
                    </div>
                    <div class="column col-6 mt-2 d-flex">
                        <form-toggle-input
                            id="level-1"
                            name="level"
                            v-model="level"
                            :true-value="1"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Current Level: ") }}</template>
                            {{ $root.translate('Level 1') }}
                        </form-toggle-input>
                        <form-toggle-input
                            id="level-2"
                            name="level"
                            v-model="level"
                            :true-value="2"
                            :required="false"
                            :horizontal="true"
                        >
                            {{ $root.translate('Level 2') }}
                        </form-toggle-input>
                        <form-toggle-input
                            id="level-3"
                            name="level"
                            v-model="level"
                            :true-value="3"
                            :required="false"
                            :horizontal="true"
                        >
                            {{ $root.translate('Level 3') }}
                        </form-toggle-input>
                    </div>

                    <div class="column col-8 mt-2">
                        <address-input
                            id="address"
                            name="address"
                            v-model="address"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Address") }}</template>
                        </address-input>
                    </div>

                    <div class="column col-5 mt-2">
                        <form-textbox
                            id="comment"
                            name="comment"
                            v-model="comment"
                            size="full"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Quick comment") }}</template>
                        </form-textbox>
                    </div>
                    <div class="column col-5 mt-2">
                        <form-editor
                            id="html_comment"
                            :data-version="user_id"
                            name="html_comment"
                            v-model="html_comment"
                            size="lg"
                            :required="false"
                        >
                            <template v-slot:label>{{ $root.translate("Styled Comment") }}</template>
                        </form-editor>
                    </div>
                    <div class="column col-2 mt-2">
                        <div
                            class="nibnut-aspect-ratio-box nibnut-ratio-square"
                        >
                            <div>
                                <image-upload-input
                                    :url="profile_image"
                                    name="image_url"
                                    :alt="$root.translate('Test image')"
                                    :accepts="upload_accepts('image_url')"
                                    :uploading="!!uploading.image_url"
                                    :uploaded="uploaded.image_url||0"
                                    @upload="upload_file_list('image_url', $event)"
                                    @clear="confirm_clear_file('image_url')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </base-form>

            <h3 class="my-4">Links</h3>
            <base-link
                href="https://nibnut.com"
                target="_blank"
            >
                <base-image :src="nibnut_logo" alt="nibnut" />
            </base-link>

            <h3 class="my-4">ModalDialogs &amp; Feedback</h3>
            <button @click.prevent="testing=!testing" class="btn btn-primary">Modal</button>
            <button @click.prevent="confirm_it" class="btn ml-3">Confirm</button>
            <button @click.prevent="notify" class="btn ml-3">Notify</button>

            <h3 class="my-4">DataTable</h3>
            <data-table
                id="sample-data-table"
                :columns="visible_columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="true"
                :selection="selected_row_ids"
                @select="select_row"
                @select-all-none="select_rows"
                @clear-selection="clear_selection"
                @filter-by-selection="filter_by_selection"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
            >
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>#{{ row.id }} {{ row.title }}</h6>
                    <p class="text-gray text-small">{{ row.amount / 100 | nibnut.number("0,00.00") }}</p>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <span v-if="field === 'amount'">{{ row[field] / 100 | nibnut.number("0,00.00") }}</span>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>
            <div v-if="!!selected_row_ids" class="toast">
                  <h6>Selection</h6>
                  <p>{{ selected_row_ids }}</p>
            </div>
            <default-button
                v-else
                flavor="link"
                :block="true"
                @click.prevent="selected_row_ids = []"
            >
                Select rows...
            </default-button>

            <h3 class="my-4">Containers</h3>
            <tabs
                :tabs="[ { id: 'tab1', title: 'Tab 1' }, { id: 'tab2', title: 'Tab 2' } ]"
                :current-tab-id="current_tab_id"
                class="mt-4 p-4"
                @click="current_tab_id=$event"
            >
                <ui-catalog-tab
                    v-if="current_tab_id === 'tab1'"
                    title="Tab 1"
                />
                <ui-catalog-tab
                    v-else-if="current_tab_id === 'tab2'"
                    title="Tab 2"
                />
            </tabs>

            <div class="columns"><!-- [MODULE:CHARTS[ //-->
                <div class="column col-6 col-sm-12">
                    <h3 class="my-4">Charts</h3>

                    <apexchart
                        ref="chart"
                        :height="300"
                        type="donut"
                        :options="chart_options"
                        :series="chart_data"
                    />
                </div>
            <!-- ]MODULE:CHARTS] //--></div>

            <modal-dialog
                id="test-dialog"
                :show.sync="testing"
            >
                <template v-slot:title><span class="h5">Testing</span></template>
                This is a modal dialog....
            </modal-dialog>

            <confirmation
                v-if="confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </page>
</template>

<script>
import { is_page, profile_utilities, string_utilities, handles_uploads, confirms, is_data_table_source } from "@/nibnut/mixins"

import {
    DefaultButton,
    FeedbackButton,
    BaseForm,
    FormInput,
    FormToggleInput,
    FormSelect,
    FormDropdown,
    FormTagInput,
    FormTokenInput,
    PasswordInput,
    PasswordEditor,
    FormTextbox,
    FormEditor,
    FormDateInput,
    AddressInput,
    ImageUploadInput,
    BaseLink,
    BaseImage,
    ModalDialog,
    DataTable,
    Tabs,
    OpenIcon
} from "@/nibnut/components"

import UiCatalogTab from "./UiCatalogTab"
/** [MODULE:CHARTS[ **/
import VueApexCharts from "vue-apexcharts"/** ]MODULE:CHARTS] **/
import nibnut_logo from "@/assets/img/nibnut.png"

export default {
    mixins: [is_page, profile_utilities, string_utilities, handles_uploads, confirms, is_data_table_source],
    components: {
        DefaultButton,
        FeedbackButton,
        BaseForm,
        FormInput,
        FormToggleInput,
        FormSelect,
        FormDropdown,
        FormTagInput,
        FormTokenInput,
        PasswordInput,
        PasswordEditor,
        FormTextbox,
        FormEditor,
        FormDateInput,
        AddressInput,
        ImageUploadInput,
        BaseLink,
        BaseImage,
        ModalDialog,
        DataTable,
        Tabs,
        OpenIcon,
        UiCatalogTab/** [MODULE:CHARTS[ **/,
        Apexchart: VueApexCharts/** ]MODULE:CHARTS] **/
    },
    watch: {
        "held.image_url": "regenerate_held_image"
    },
    methods: {
        wait () {
            this.waiting = true
            setTimeout(() => {
                this.waiting = false
            }, 3000)
        },
        confirm_it () {
            this.confirm(
                {
                    title: "Are you sure?",
                    message: "Make sure you are sure."
                }
            )
        },
        confirmed () {
            console.log("Confirmed")
            this.done_confirming()
        },
        notify () {
            this.$error("ERROR: How can you have any puddin' if you don't eat your meat???")
        },
        add_tag (label, field) {
            const new_tag = { id: this.tags.length + 1, name: label }
            this.tags.push(new_tag)
            this[field].push(new_tag.id)
        },
        validate_email (email) {
            return !!email && !!email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        },
        add_email (email) {
            email = email.toLowerCase()
            const index = this.emails.indexOf(email)
            if(index < 0) this.emails.push(email)
        },
        remove_email (email) {
            const index = this.emails.indexOf(email.toLowerCase())
            if(index >= 0) this.emails.splice(index, 1)
        },
        map_bubble () {
            return `
                <div>${this.address_block_line(1, this.address)}</div>
                <div>${this.address_block_line(2, this.address)}</div>
            `
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        regenerate_held_image () {
            this.generate_held_image("image_url")
        }
    },
    computed: {
        nibnut_logo () {
            return nibnut_logo
        },
        roles () {
            return Object.values(this.constants("roles")).map(role => {
                return { ...role, disabled: (role.id > 10) }
            })
        },
        rows () {
            return [
                { id: 1, title: "One", amount: 100 },
                { id: 2, title: "Two", amount: 200 },
                { id: 3, title: "Three", amount: 300 },
                { id: 4, title: "Four", amount: 400 },
                { id: 5, title: "Five", amount: 500 },
                { id: 6, title: "Six", amount: 600 },
                { id: 7, title: "Seven", amount: 700 },
                { id: 8, title: "Eight", amount: 800 },
                { id: 9, title: "Nine", amount: 900 }
            ]
        },
        holding_mode () {
            return true
        },
        profile_image () {
            if(this.held_images.image_url) return this.held_images.image_url.src
            return this.image_url
        }/** [MODULE:CHARTS[ **/,
        chart_options () {
            return {
            }
        },
        chart_data () {
            return [44, 55, 41, 17, 15]
        }/** ]MODULE:CHARTS] **/
    },
    data () {
        return {
            email: "appmaker@nibnut.com",
            password: "very-p0@r-passowrd",
            amount: 10,
            user_id: 0,
            role: 0,
            current_tags: [1],
            team: [],
            emails: [],
            comment: "",
            html_comment: "<strong>Hi!</strong> How are you?",
            image_url: "",
            meeting_on: "",
            meeting_confirmed: false,
            level: 0,
            waiting: false,
            testing: false,
            feedback_status: null,
            current_tab_id: "tab1",
            address: {
                line1: "",
                line2: "",
                city: "",
                state: "QC",
                country: "CA",
                zip: "",
                latitude: 0.0,
                longitude: 0.0
            },

            tags: [
                { id: 1, name: "Zia" },
                { id: 2, name: "Pois Chiche" },
                { id: 3, name: "Petit Prince" },
                { id: 4, name: "Rezin" },
                { id: 5, name: "Fabada" },
                { id: 6, name: "Pogo" }
            ],

            columns: {
                id: { label: "#", sort: "desc", type: "numeric" },
                title: { label: "Title", sort: null, type: "alpha" },
                amount: { label: "Amount", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "id",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
